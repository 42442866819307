const TRUTHY_VALUES = ["true", ""];
/**
 * Represents the configuration for a form.
 * This class is responsible for parsing and storing the configuration for a form from an HTML element.
 * It extracts the form ID, API base URL, target ID, and lazy loading status from the element's attributes.
 * @param {HTMLElement} element - The HTML element containing the form configuration.
 */
class FormConfig {
    /**
     * Initializes a new instance of the FormConfig class.
     * 
     * @param {HTMLElement} element - The HTML element containing the form configuration.
     */
    constructor(element) {
      if (!(element instanceof HTMLElement)) {
        return;
      }
  
      this.containerId = element.id;
      this.formId = element.dataset.formId;
      this.apiBaseUrl = element.dataset.apiBaseUrl;
      this.lazy = TRUTHY_VALUES.includes(element.dataset.lazy);
      
      if (this.formId && this.apiBaseUrl) {
        this.initialized = true;
      }
    }
  }

  export default FormConfig;