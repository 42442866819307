
import FormConfig from "./form-config"

/**
 * Represents the Endatix class.
 * This class is responsible for initializing and managing the Endatix forms.
 */
class Endatix {
  /**
   * Initializes a new instance of the Endatix class by querying the document for elements with the 'edx-form' data role.
   */
  constructor() {
    this.formTargets = [...document.querySelectorAll("[data-role='edx-form']")];
  }

  /**
   * Checks if there are any forms to initialize.
   * @returns {boolean} - True if there are forms, false otherwise.
   */
  hasForms = () => this.formTargets?.length > 0;

  /**
   * Gets the lazy loading observer for the forms.
   * The observer is created if it doesn't exist.
   * Note: Use this.lazyLoadingObserver instead of this._lazyLoadingObserver
   * @returns {IntersectionObserver} - The lazy loading observer.
   */
  get lazyLoadingObserver() {
    if (!this._lazyLoadingObserver) {
      this._lazyLoadingObserver = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.initForm(new FormConfig(entry.target));
              // unobserve the element as we want to use it one
              this._lazyLoadingObserver.unobserve(entry.target);
            }
          });
        },
        {
          root: null, // Use the viewport as the root
          rootMargin: "800px",
          threshold: 0, // Trigger when 0% of the element (first pixel) is visible
        }
      );
    }
    return this._lazyLoadingObserver;
  }

  /**
   * Lightweight form initializer, which uses dynamic imports to only load the forms scripts and styles if there are forms
   * If a form is lazy, it is observed by the lazy loading observer.
   * If a form is not lazy, it is initialized immediately.
   */
  initializeForms() {
    this.formTargets.forEach(target => {
      const formConfig = new FormConfig(target);
      if (formConfig.lazy) {
        this.lazyLoadingObserver.observe(target);
      } else {
        this.initForm(formConfig);
      }
    });
  }

  /**
   * Initializes a form.
   * The form module is imported and a new instance of the form is created.
   * If there is an error loading the form module, an error message is logged.
   * @param {FormConfig} formConfig - The configuration for the form.
   */
  initForm(formConfig) {
    import("@/js/endatix/endatix-form")
      .then(({ default: EndatixForm }) => {
        const form = new EndatixForm(formConfig);
        form.init()
      })
      .catch((error) => {
        console.error("Error loading the form module:", error);
      });
  }
}

export default Endatix;