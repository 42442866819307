import "@/scss/main.scss";

import $ from "jquery";
window.$ = $;
window.jQuery = $;

import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import Endatix from "../js/endatix/endatix";

$(function () {
  "use strict";

  const debounce = (callback, waitTime) => {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        callback(...args);
      }, waitTime);
    };
  };

  var header = $(".no-background");
  $(window).on("scroll", function () {
    var scroll = $(window).scrollTop();
    if (scroll >= 1) {
      header.removeClass("no-background").addClass("navbar-bg");
    } else {
      header.removeClass("navbar-bg").addClass("no-background");
    }
  });

  //multi dropdown
  $(".dropdown-menu a.dropdown-toggle").on("click", function (e) {
    var $el = $(this);
    var $parent = $(this).offsetParent(".dropdown-menu");
    if (!$(this).next().hasClass("show")) {
      $(this)
        .parents(".dropdown-menu")
        .first()
        .find(".show")
        .removeClass("show");
    }
    var $subMenu = $(this).next(".dropdown-menu");
    $subMenu.toggleClass("show");

    $(this).parent("li").toggleClass("show");

    $(this)
      .parents("li.nav-item.dropdown.show")
      .on("hidden.bs.dropdown", function (e) {
        $(".dropdown-menu .show").removeClass("show");
      });

    if (!$parent.parent().hasClass("navbar-nav")) {
      $el.next().css({ top: $el[0].offsetTop, left: $parent.outerWidth() - 4 });
    }

    return false;
  });

  //Navbar top search
  $(".navbar").each(function () {
    $("li.search > a", this).on("click", function (e) {
      e.preventDefault();
      $(".top-search").slideToggle();
    });
  });
  $(".input-group-addon.close-search").on("click", function () {
    $(".top-search").slideUp();
  });

  //Sidebar
  $(".overlay").on("click", function () {
    $("#sidebar, .page-content").toggleClass("active");
    $("#sidebar").removeClass("active");
    $(".overlay").fadeOut();
  });

  // Smooth scrolling using jQuery easing
  $('a.js-scroll-trigger[href*="#"]:not([href="#"])').on("click", function () {
    if (
      location.pathname.replace(/^\//, "") ===
      this.pathname.replace(/^\//, "") &&
      location.hostname === this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      if (target.length) {
        $("html, body").animate(
          {
            scrollTop: target.offset().top - 10,
          },
          1000,
          "easeInOutExpo"
        );
        return false;
      }
    }
  });

  //Back to top
  $("body").append(
    '<div id="toTop" class="btn-top"><i class="ti-upload"></i></div>'
  );
  $(window).scroll(function () {
    if ($(this).scrollTop() !== 0) {
      $("#toTop").fadeIn();
    } else {
      $("#toTop").fadeOut();
    }
  });
  $("#toTop").on("click", function () {
    $("html, body").animate({ scrollTop: 0 }, 600);
    return false;
  });
});

$(window)
  .on("scroll", function () {
    var scrollDistance = $(window).scrollTop();
    // Assign active class to nav links while scolling
    $(".page-section").each(function (i) {
      if ($(this).position().top <= scrollDistance) {
        $(".navigation a.active").removeClass("active");
        $(".navigation a").eq(i).addClass("active");
      }
    });
  })
  .trigger("scroll");

function initializeEndatixForms() {
  const endatix = new Endatix();
  endatix.initializeForms();
}

document.addEventListener("DOMContentLoaded", () => {
  const preElements = $("pre code");
  if (preElements?.length) {
    import("@/js/code-syntax-highlighter").then(
      ({ default: highlightCodeSyntax }) => {
        highlightCodeSyntax(preElements);
      }
    );
  }

  const images = document.querySelectorAll('.post-img > img');
  const overlay = document.querySelector('#img-overlay');
  if (images?.length && overlay) {
    images.forEach(img => {
      img.addEventListener('click', () => {
        overlay.style.backgroundImage = 'url(' + img.src + ')';
        overlay.style.display = 'block';
      });
    });
  }

  initializeEndatixForms();
});